import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import React from 'react';
import {
    ContainerGrid,
    ContainerGridItem,
    SellRateField,
    BuyRateField,
    CreditDecisionLenderNameField,
    CreditDecisionStatusField,
    CreditBureau
} from '@makemydeal/dr-dash-components';
import { useDispatch, useSelector } from 'react-redux';
import { baseDealSelectors, dealXgDetailsSelectors, navigationActionCreators } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { Box } from '@interstate/components/Box';

const BaseDealLeander = () => {
    const dispatch = useDispatch();
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const orderDetailsLink = useSelector(dealXgDetailsSelectors.getOrderDetailsLink);
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const enableCreditBureauAndDecisionPBCRouteOne = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCRouteOne);
    const isCreditProviderRouteOne: boolean = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const isBreakdownOpen = useSelector(baseDealSelectors.getBreakdownStatus);

    const enableCreditDecisionForR1 =
        enableCreditBureauAndDecisionPBCs && enableCreditBureauAndDecisionPBCRouteOne && isCreditProviderRouteOne;
    const enableCreditDecisionForDealerTrack = enableCreditBureauAndDecisionPBCs && !isCreditProviderRouteOne;
    const shouldNavigateToCreditDecision = enableCreditDecisionForDealerTrack || enableCreditDecisionForR1;
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());
    const navigateToCreditBureau = () => dispatch(navigationActionCreators.navigateToCreditBureau());

    const handleViewLenderDecisionClick = () => {
        if (isEcommerceDeal) {
            window.open(`${orderDetailsLink}?show=ildp`, '_blank');
        } else if (shouldNavigateToCreditDecision) {
            navigateToCreditDecision();
        } else {
            navigateToCreditBureau();
        }
    };

    const leaseFields = [
        { component: <CreditDecisionLenderNameField />, key: 'CreditDecisionLenderNameField' },
        { component: <CreditDecisionStatusField />, key: 'CreditDecisionStatusField' },
        { component: <SellRateField />, key: 'SellRateField' },
        { component: <BuyRateField />, key: 'BuyRateField' }
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={3}>
                <Typography data-testid="lender-title" variant="h3">
                    Lender Information
                </Typography>
                <Action size="sm" data-testid="lender-view-lender-decision" onClick={handleViewLenderDecisionClick}>
                    View Lender Decisions
                </Action>
            </Box>
            <ContainerGrid data-testid="base-deal-form-fields">
                {leaseFields.map(({ key, component }) => (
                    <ContainerGridItem key={key} xs={6} md={isBreakdownOpen ? 6 : 3} data-testid={`base-deal-form-fields-${key}`}>
                        {component}
                    </ContainerGridItem>
                ))}
            </ContainerGrid>
            <CreditBureau />
        </Box>
    );
};

export default BaseDealLeander;
