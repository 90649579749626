import styled from 'styled-components';
import { Box } from '@interstate/components/Box';
import { SlideOut } from '@interstate/components/SlideOut';

export const ViewAllScenariosAction = styled(Box)`
    display: flex;
    width: 100%;
    background-color: rgba(244, 244, 244, 0.96);
    padding: 0.5rem 1rem;
    justify-content: flex-end;
`;

export const StyledSlideOutFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    background-color: rgba(244, 244, 244, 0.96);
    border-top: 1px solid #b2b2b2;
    padding: 0.5rem 1.25rem;
`;

export const StyledSlideOutContent = styled(SlideOut)`
    .ids-SlideOut-content {
        padding: 0 1.5rem 5rem 1.5rem;
    }
`;
