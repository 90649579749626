// externals
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { CheckBox } from '@interstate/components/CheckBox';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate/components/Box';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { offerActionCreators, offerReduxSelectors, paymentSelectors } from '@makemydeal/dr-dash-store';
import { actionCreators } from '@makemydeal/dr-offer-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { CRT_TAX, UPFRONT } from '../constants';

// components
import EmptyCategoryFallback from './EmptyCategoryFallback';
import { TaxCategoryHeading } from './TaxCategory.style';
import TaxItemSummary from './TaxItemSummary';

interface TaxCategorySectionProps {
    categoryTitle: string;
    taxItems: paymentServicesTypes.TaxItem[];
    dataTestId: string;
    showUpfrontOverride?: boolean;
    overrideUpfront?: boolean;
    hideTaxNames?: boolean;
    taxOverrideEnabled?: boolean;
}

const TaxCategory: React.FC<TaxCategorySectionProps> = ({
    categoryTitle,
    taxItems,
    dataTestId,
    showUpfrontOverride,
    overrideUpfront,
    hideTaxNames,
    taxOverrideEnabled
}) => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const [upfrontChecked, switchCheckbox] = useState(overrideUpfront);
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);
    const isBaseDealScreenExperience = useSelector(featureToggleSelectors.useDealScreenExperience);
    const totalTaxOverrideEnabled = useSelector(offerReduxSelectors.hasManualTotalTax);
    const [lastShownTaxItems, setLastShownTaxItems] = useState<paymentServicesTypes.TaxItem[]>([]);

    /**
     * NOTE: This is used to fix DE543326.
     * Problem statement: Tax list UI jumps on each payment request when we override total tax amount.
     * This creates bad UI experience for the user.
     * We clear tax items in redux store on each payment request, because we have some logic we handle on UI on payment response.
     * So when we override total tax amount we need to preserve the tax items in the UI.
     */
    useEffect(() => {
        if (totalTaxOverrideEnabled && taxItems?.length > 0) {
            setLastShownTaxItems(taxItems);
        }

        if (!totalTaxOverrideEnabled) {
            setLastShownTaxItems(taxItems);
        }
    }, [taxItems, totalTaxOverrideEnabled]);

    useEffect(() => {
        if (!isPaymentCalculating && overrideUpfront !== upfrontChecked) {
            switchCheckbox(overrideUpfront);
        }
    }, [overrideUpfront, isPaymentCalculating, upfrontChecked]);

    const onUpfrontOverride = useCallback(() => {
        if (taxItems?.length) {
            dispatch(
                actionCreators.updateTaxItems(
                    taxItems.map((taxItem) => ({
                        ...taxItem,
                        isManualTax: true,
                        isCapitalize: upfrontChecked
                    })),
                    offerType,
                    false
                )
            );
        }

        /** NOTE: updateTaxItem's isCapitalize is stateless and PO response will flush it,
         *  so we need to use `updatedTotalTaxUpfrontOverride` to preserve the choice globally
         */
        dispatch(offerActionCreators.updatedTotalTaxUpfrontOverride(!upfrontChecked));

        switchCheckbox(!upfrontChecked);
    }, [dispatch, offerType, taxItems, upfrontChecked]);

    const displayTaxItems = taxItems || lastShownTaxItems;

    return (
        <Box data-testid={dataTestId} marginTop={1}>
            <TaxCategoryHeading isBaseDealScreenExperience={isBaseDealScreenExperience} theme={theme}>
                <div>
                    <Typography variant="h5">{categoryTitle}</Typography>
                </div>
                {isOverrideTaxAmountForPaymentEnabled && showUpfrontOverride && (
                    <CheckBox
                        data-testid={`${dataTestId}-upfront-checkbox`}
                        id={kebabCase(UPFRONT)}
                        label={'Upfront'}
                        name={kebabCase(UPFRONT)}
                        onChange={onUpfrontOverride}
                        checked={upfrontChecked}
                        value="1"
                    />
                )}
            </TaxCategoryHeading>
            <Box>
                {displayTaxItems?.length ? (
                    displayTaxItems.map((taxItem, i) => (
                        <TaxItemSummary
                            data-testid={taxItem.taxName}
                            taxItem={taxItem}
                            key={taxItem.taxName + i}
                            hideTaxName={hideTaxNames}
                            taxOverrideEnabled={taxOverrideEnabled}
                        />
                    ))
                ) : isOverrideTaxAmountForPaymentEnabled ? (
                    <Typography variant="body-sm" sx={{ marginBottom: 3, paddingLeft: 2, paddingRight: 2 }}>
                        N/A
                    </Typography>
                ) : (
                    <EmptyCategoryFallback />
                )}
            </Box>
        </Box>
    );
};

export default TaxCategory;
